<template>
    <div class="modal-basic">
        <ForceCheck />
        <div v-if="buttons" class="buttons-basic m-t-16">
            <div class="flex-row">
                <button @click="$emit('close')" :key="idx" v-for="(btn, idx) in buttons" :class="btn.class">
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ForceCheck from '@/routes/my-page/components/ForceCheck'
export default {
    name: 'ModalForceCheck',
    components: {
        ForceCheck,
    },
    props: ['options'],
    computed: {
        buttons() {
            return (this.options || {}).buttons || []
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    padding: 20px 20px 0 20px;
}
</style>
