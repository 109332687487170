<template>
    <div class="force-check">
        <div class="item m-b-12">
            <div class="item-title">
                <span>체크 시</span>
                <img :src="require('@/assets/images/select-active.png')" alt="" />
            </div>
            <div class="item-content">
                선택한 조건의 회원“만” 소개해 드립니다. 다른 좋은 조건의 회원이 있어도 이 조건이 만족되지 않으면
                소개드리지 않아요.
            </div>
        </div>
        <div class="item">
            <div class="item-title">
                <span>체크하지 않을시</span>
                <img :src="require('@/assets/images/select-inactive.png')" alt="" />
            </div>
            <div class="item-content">
                선택한 조건의 회원을 우선으로 소개해 드립니다. 만약 좋은 조건의 회원이 있으면 소개될 수 있어요.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForceCheck',
}
</script>

<style scoped lang="scss">
.item {
    text-align: left;

    .item-title {
        color: black;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        @include spoqa-f-bold;

        img {
            margin-left: 4px;
            width: 12px;
            height: 12px;
        }
    }
    .item-content {
        color: black;
        line-height: 20px;
        @include spoqa-f-regular;
    }
}
</style>
